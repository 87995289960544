import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
    ColumnFiltersState,
    VisibilityState,
    SortingState,
    ColumnOrderState
} from '@tanstack/react-table';
import {
    selectEquipmentAttributes,
    selectEquipmentColumnFilters,
    selectEquipmentColumnOrder,
    selectEquipmentColumnVisibility,
    selectEquipmentGlobalFilter,
    selectEquipmentSorting,
    setEquipmentAttributes,
    setEquipmentColumnFilters,
    setEquipmentColumnOrder,
    setEquipmentColumnVisibility,
    setEquipmentGlobalFilter,
    setEquipmentSorting
} from '~/reducers/equipmentTableSlice';
import constants from '~/utils/constants';
import { OpenSearchAttributeCount } from '~/api/types';
import {
    equipmentTableColumnIdAndSortableKeyMapping,
    EquipmentTableColumnIds as columnIds
} from '../types';

export const useEquipmentTableStates = () => {
    const attributes = useSelector(selectEquipmentAttributes);
    const columnFilters = useSelector(selectEquipmentColumnFilters);
    const columnOrder = useSelector(selectEquipmentColumnOrder);
    const columnVisibility = useSelector(selectEquipmentColumnVisibility);
    const sorting = useSelector(selectEquipmentSorting);
    const globalFilter = useSelector(selectEquipmentGlobalFilter);

    const dispatch = useDispatch();

    const setAttributes = useCallback(
        (payload: Record<string, OpenSearchAttributeCount[]>) => {
            dispatch(setEquipmentAttributes(payload));
        },
        [dispatch]
    );

    const setColumnFilters = useCallback(
        (payload: ColumnFiltersState) => {
            dispatch(setEquipmentColumnFilters(payload));
        },
        [dispatch]
    );

    const setColumnOrder = useCallback(
        (payload: ColumnOrderState) => {
            dispatch(setEquipmentColumnOrder(payload));
        },
        [dispatch]
    );

    const setColumnVisibility = useCallback(
        (payload: VisibilityState) => {
            dispatch(setEquipmentColumnVisibility(payload));
        },
        [dispatch]
    );

    const setSorting = useCallback(
        (payload: SortingState) => {
            dispatch(setEquipmentSorting(payload));
        },
        [dispatch]
    );

    const onApplySorting = useCallback(
        (id: string, sortingType: string) => {
            setSorting([
                {
                    id: equipmentTableColumnIdAndSortableKeyMapping[id],
                    desc: sortingType === constants.descending
                }
            ]);
        },
        [setSorting]
    );

    const onClearSorting = useCallback(() => {
        setSorting([]);
    }, [setSorting]);

    const onClearColumnOrder = useCallback(() => {
        setColumnOrder(columnIds);
    }, [setColumnOrder]);

    const onClearColumnVisibility = useCallback(() => {
        const visibleColumns = columnIds.map((columnId) => [columnId, true]);
        setColumnVisibility(Object.fromEntries(visibleColumns));
    }, [setColumnVisibility]);

    const setGlobalFilter = useCallback(
        (payload: string) => {
            dispatch(setEquipmentGlobalFilter(payload));
        },
        [dispatch]
    );

    useEffect(() => {
        // columnOrder should never be empty or have a length that does not match the total columns
        if (!columnOrder.length || columnOrder.length !== columnIds.length) {
            onClearColumnOrder();
        }
    }, [columnOrder, onClearColumnOrder]);

    useEffect(() => {
        // columnOrder should never be empty or have a `keys` length that does not match the total columns
        if (
            isEmpty(columnVisibility) ||
            Object.keys(columnVisibility).length !== columnIds.length
        ) {
            onClearColumnVisibility();
        }
    }, [columnVisibility, onClearColumnVisibility]);

    return {
        globalFilter,
        attributes,
        columnFilters,
        columnOrder,
        columnVisibility,
        sorting,
        setAttributes,
        setGlobalFilter,
        setColumnFilters,
        setColumnOrder,
        setColumnVisibility,
        setSorting,
        onApplySorting,
        onClearSorting,
        onClearColumnOrder,
        onClearColumnVisibility
    };
};
