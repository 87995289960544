import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InventoryItemsApi from '~/api/InventoryItemsApi';
import TasksApi from '~/api/TasksApi';
import CustomersApi from '~/api/CustomersApi';
import { Task } from '~/data-classes';
import { ApiInventoryItem, ApiCustomerNotes, ApiTask } from '~/api/types';
import { useToastContext } from '~/hooks';
import generalUtils from '~/utils/general-utils';
import { useMemo } from 'react';
import { addNewTask, selectTaskById } from '~/reducers/tasksSlice';
import taskUtils from '~/utils/task-utils';

const INVENTORY_ITEMS_API_LIMIT = 1000;

interface TaskCustomerNotesData {
    /**
     * The customer ID
     */
    customerId: string;

    /**
     * The customer notes
     */
    notes: ApiCustomerNotes[] | undefined;
}

interface TaskCustomerNotes {
    /**
     * The delivery task customer notes data
     */
    deliveryCustomer?: TaskCustomerNotesData;

    /**
     * The pickup task customer notes data
     */
    pickupCustomer?: TaskCustomerNotesData;
}

interface UseGetTaskDetailsParams {
    /**
     * the task ID
     */
    taskId?: string | null;
}

interface TaskDetails {
    /**
     * The task API data
     */
    apiTask: ApiTask | undefined;

    /**
     * The task data
     */
    taskData: Task | undefined;

    /**
     * The task inventory items
     */
    taskInventoryItems: ApiInventoryItem[] | undefined;

    /**
     * The task customer notes
     */
    taskCustomerNotes: TaskCustomerNotes;
}

const getMappedApiTask = (data?: ApiTask): ApiTask | undefined => {
    if (!data) return undefined;

    const taskStatus = taskUtils.getTaskStatus(data);

    return {
        ...data,
        taskStatus
    };
};

export const useGetTaskDetails = ({
    taskId
}: UseGetTaskDetailsParams): TaskDetails => {
    const { t } = useTranslation('error');
    const { addToast } = useToastContext();
    const dispatch = useDispatch();
    const selectedTask = useSelector(selectTaskById(taskId)) as
        | ApiTask
        | undefined;
    const getTask = async (id: string): Promise<ApiTask | undefined> => {
        try {
            const {
                data: { data }
            } = await TasksApi.getTask(id);
            const apiTask = getMappedApiTask(data);

            dispatch(addNewTask(apiTask));
            return apiTask;
        } catch (err) {
            console.error(err);
            addToast({
                message: t('taskDetailsError'),
                variant: 'error'
            });
            return undefined;
        }
    };

    const getInventoryItems = async (id: string) => {
        const {
            data: { data }
        } = await InventoryItemsApi.get({
            task_id: id,
            signed: true,
            limit: INVENTORY_ITEMS_API_LIMIT
        });
        return data;
    };

    const getCustomerNotes = async (id: string) => {
        const {
            data: { data }
        } = await CustomersApi.getCustomerNotes(id);
        return data;
    };

    const isValidTaskId = taskId ? generalUtils.isValidUUID(taskId) : false;

    const { data: taskFetchData } = useQuery(
        [TasksApi.REACT_QUERY_KEY_TASK, taskId],
        () => getTask(taskId ?? ''),
        {
            enabled: !selectedTask && isValidTaskId
        }
    );

    const { apiTask, taskData } = useMemo(() => {
        const mappedApiTask = getMappedApiTask(selectedTask ?? taskFetchData);

        if (!mappedApiTask) return {};

        return {
            apiTask: mappedApiTask,
            taskData: new Task(mappedApiTask)
        };
    }, [selectedTask, taskFetchData]);

    const { deliveryCustomerId, pickupCustomerId } = taskData || {};

    const isValidDeliveryCustomerId =
        !!deliveryCustomerId && generalUtils.isValidUUID(deliveryCustomerId);

    const isValidPickupCustomerId =
        !!pickupCustomerId && generalUtils.isValidUUID(pickupCustomerId);

    // TODO: move to ~/hooks/useInventoryItem file
    const { data: taskInventoryItems } = useQuery(
        [InventoryItemsApi.REACT_QUERY_KEY, taskId],
        () => getInventoryItems(taskId ?? ''),
        {
            initialData: [],
            enabled: isValidTaskId,
            onError: () => {
                addToast({
                    message: t('inventoryItemsFetchError'),
                    variant: 'error'
                });
            }
        }
    );

    const { data: customerNotesDelivery } = useQuery(
        [CustomersApi.REACT_QUERY_KEY_CUSTOMER, deliveryCustomerId],
        () => getCustomerNotes(deliveryCustomerId as string),
        {
            initialData: [],
            enabled: isValidDeliveryCustomerId
        }
    );

    const { data: customerNotesPickup } = useQuery(
        [CustomersApi.REACT_QUERY_KEY_CUSTOMER, pickupCustomerId],
        () => getCustomerNotes(pickupCustomerId as string),
        {
            initialData: [],
            enabled: isValidPickupCustomerId
        }
    );

    const taskCustomerNotes = useMemo(() => {
        const deliveryCustomer = isValidDeliveryCustomerId
            ? {
                  customerId: deliveryCustomerId,
                  notes: customerNotesDelivery
              }
            : undefined;

        const pickupCustomer = isValidPickupCustomerId
            ? {
                  customerId: pickupCustomerId,
                  notes: customerNotesPickup
              }
            : undefined;

        return { deliveryCustomer, pickupCustomer } as TaskCustomerNotes;
    }, [
        isValidDeliveryCustomerId,
        isValidPickupCustomerId,
        customerNotesDelivery,
        customerNotesPickup,
        deliveryCustomerId,
        pickupCustomerId
    ]);

    return {
        apiTask,
        taskData,
        taskInventoryItems,
        taskCustomerNotes
    };
};
