import i18n from 'i18next';
import { isString } from 'lodash';
import constants from '../constants';
import { appVersion } from '~/version';

/**
 * General Utilities
 *
 * @category Utils
 * @module utils/generalUtils
 *
 * @example
 * import generalUtils from '~/utils/general-utils';
 */

export const getAppVersion = () => {
    return appVersion;
};

/**
 * Get the capitalized first letter of a string
 *
 * @param {String} str
 * @returns {string}
 */
function getCapitalizedFirstChar(str: string): string {
    return isString(str) ? str.charAt(0).toUpperCase() : '';
}

function capitalizeFirstChar(str: string): string {
    if (!str) {
        return '';
    }
    return getCapitalizedFirstChar(str) + str.slice(1);
}

/**
 * Return user's localized full name, if unavailable, returns alternative
 *
 * @param {String} firstName
 * @param {String} lastName
 * @param {String} alternative
 * @returns {String} full name or alternative
 */
function getUserName(
    firstName: string,
    lastName: string,
    alternative = ''
): string {
    if (!firstName && !lastName) {
        return alternative;
    }
    return i18n.t('common:name.full', { firstName, lastName }).trim();
}

/**
 * Return user's localized initials, if unavailable, returns alternative
 *
 * @param {String} firstName
 * @param {String} lastName
 * @param {String} alternative
 * @returns {String} initials or alternative
 */
function getUserInitials(
    firstName: string,
    lastName: string,
    alternative = ''
): string {
    if (!firstName && !lastName) {
        return alternative;
    }
    const firstInitial = getCapitalizedFirstChar(firstName);
    const lastInitial = getCapitalizedFirstChar(lastName);
    return i18n
        .t('common:name.initials', { firstInitial, lastInitial })
        ?.trim();
}

/**
 * Displays a flagged feature based on detected hostname (local, stage or sandbox)
 * or if provided, the value of `enableFeatureFlags` in localStorage
 *
 * @example
 * // default, tests the user's hostname against a preset list
 * isFlaggedFeatureDisplayed()
 *
 * @returns {Boolean}
 */
function isFlaggedFeatureDisplayed(): boolean {
    // A way to override default feature flag settings.
    // For example: to test the UAT/Prod interface on Stage,
    // we can manually set `enableFeatureFlags: false` in Local Storage.
    // To test the Stage interface on UAT/Prod,
    // we can manually set `enableFeatureFlags: true` in Local Storage.
    const featureFlagsOverride =
        localStorage.getItem(constants.localStorageKeys.ENABLE_FEATURE_FLAGS) ||
        '';
    const featureFlagsOverrideLowercase = featureFlagsOverride.toLowerCase();
    // explicitly checking for 'false' values to allow disabling flagged features on Stage
    if (['true', 'false'].includes(featureFlagsOverrideLowercase)) {
        return featureFlagsOverrideLowercase === 'true';
    }

    // fallback to detecting hostname from a preset list
    const { hostname } = window.location;
    const isLocal = /localhost/.test(hostname) || /127\.0\.0\.1/.test(hostname);
    const isStage = /admin\.stage\.wisesys\.info/.test(hostname);
    const isSandbox =
        /admin\.sandbox\.wisesys\.info/.test(hostname) ||
        /wise-web-multi-client-mrs\.wisesys\.info/.test(hostname);

    return isLocal || isStage || isSandbox;
}

/**
 * Detect if window.location.hostname is for demo (admin.demo.wisesys.info)
 *
 * @returns {Boolean}
 */
function isCurrentHostDemo(): boolean {
    const { hostname } = window.location;

    return /admin\.demo\.wisesys\.info/.test(hostname);
}

/**
 * Detect if window.location.hostname is for uat (admin.uat.wisesys.info)
 *
 * @returns {Boolean}
 */
function isCurrentHostUAT(): boolean {
    const { hostname } = window.location;

    return /admin\.uat\.wisesys\.info/.test(hostname);
}

/**
 * Tests if the provided string is a valid uuid string
 *
 * @param {String} uuidValue
 * @example
 * // tests against a valid UUID string `54b8f1d7-c50a-4ab6-b2d5-c36ee6bb9053`
 * isValidUUID('54b8f1d7-c50a-4ab6-b2d5-c36ee6bb9053')
 * @returns {Boolean} true if uuidValue is valid
 */
function isValidUUID(uuidValue: string): boolean {
    return /^[a-z0-9]{8}(-[a-z0-9]{4}){3}-[a-z0-9]{12}$/.test(uuidValue);
}

/**
 * Sets a promise-based timer
 *
 * @param {number} delay - the number of milliseconds for the timer
 * @example
 * // simulates a 5 second download on click
 * const handleOnClick = async (event: MouseEvent<HTMLButtonElement>) => {
 *     setDownloadStatus('downloading');
 *     await promiseTimer(5000);
 *     setDownloadStatus('downloaded');
 * };
 * @returns {Promise} the promise timer
 */
function promiseTimer(delay: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, delay));
}

/**
 * Displays a flagged feature based on the value of `allowDispatchingFutureDates` in localStorage
 *
 * @returns {boolean}
 */

function hasLocalStorageFeatureFlag(feature: string): boolean {
    const isFeatureEnabled = localStorage.getItem(feature) || 'false';
    return isFeatureEnabled.toLowerCase() === 'true';
}

function isAllowDispatchingFutureDatesEnabled(): boolean {
    return hasLocalStorageFeatureFlag(
        constants.featureFlags.development.ALLOW_DISPATCHING_FUTURE_DATES
    );
}

function isExternalTaskTypesEnabled(): boolean {
    return hasLocalStorageFeatureFlag(
        constants.featureFlags.development.ALLOW_EXTERNAL_TASK_TYPES
    );
}

function truncateString(str: string, num: number): string {
    if (str.length <= num) {
        return str;
    }
    return `${str.slice(0, num)}...`;
}

/**
 * @param str a stringified number to be prefixed with 0s
 * @param size expected length of the returning string padded with starting 0s
 * @returns a padded string with length = size
 */
function getPaddedNDigitNumber(str: string, size: number): string {
    if (str.length >= size) return str;
    return str.padStart(size, '0');
}

/**
 * Returns the number rounded to at most n digits
 *
 * @param {Number} num
 * @param {Number} maxDigitsAfterDecimal
 * @returns {Number}
 */
function roundToMaxDigitsAfterDecimal(
    num = 0,
    maxDigitsAfterDecimal = 0
): number {
    const digitsAfterDecimal = num.toString().split('.')[1] || '';
    const numberOfDigitsAfterDecimal = digitsAfterDecimal.length;

    if (numberOfDigitsAfterDecimal <= maxDigitsAfterDecimal) return num;

    return Number(num.toFixed(maxDigitsAfterDecimal));
}

type TaskEligibleExt =
    | 'Chassis'
    | 'Bobtail'
    | 'Empty delivery'
    | 'Empty pickup';
export const taskEligibilityPairs: Record<TaskEligibleExt, TaskEligibleExt> = {
    Chassis: 'Chassis',
    Bobtail: 'Bobtail',
    'Empty delivery': 'Empty pickup',
    'Empty pickup': 'Empty delivery'
};

/**
 * Returns boolean based on eligibility check
 *
 * @param {String} task1EligibleExt
 * @param {String} task2EligibleExt
 * @returns {Boolean}
 */
const checkEligibility = (
    task1EligibleExt: string,
    task2EligibleExt: string
): boolean => {
    return (
        task1EligibleExt in taskEligibilityPairs &&
        task2EligibleExt in taskEligibilityPairs &&
        taskEligibilityPairs[task1EligibleExt as TaskEligibleExt] ===
            task2EligibleExt
    );
};

export default {
    getCapitalizedFirstChar,
    capitalizeFirstChar,
    getUserName,
    getUserInitials,
    isFlaggedFeatureDisplayed,
    isValidUUID,
    promiseTimer,
    isAllowDispatchingFutureDatesEnabled,
    isExternalTaskTypesEnabled,
    truncateString,
    getPaddedNDigitNumber,
    roundToMaxDigitsAfterDecimal,
    isCurrentHostDemo,
    isCurrentHostUAT,
    checkEligibility
};
