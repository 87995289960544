import { useMutation } from 'react-query';
import TasksApi, { AddOrUpdateTaskData } from '~/api/TasksApi';
import { AxiosApiResponse } from '~/api/types';

export const useAddTasks = () => {
    const { isLoading: isLoadingAddTasks, mutateAsync: doAddTasksAsync } =
        useMutation((apiTask: AddOrUpdateTaskData[]): Promise<
            AxiosApiResponse<Record<string, string[]>>
        > => {
            return TasksApi.addTasks(apiTask);
        });

    const addTasks = (apiTask: AddOrUpdateTaskData[]) => {
        return doAddTasksAsync(apiTask);
    };

    return {
        isLoadingAddTasks,
        addTasks
    };
};
