import { ApiTask, PickupTaskProps, TimeWindow } from '~/api/types';
import { LiveStop } from '~/data-classes';
import { OperationalStopData } from '../../types';
import { getExternalTaskType } from '../getExternalTaskType';

export const getSecondTwoPartApiTask = ({
    pickupStopData,
    deliveryStopData,
    pickupWindow,
    deliveryWindow
}: {
    pickupStopData: OperationalStopData;
    deliveryStopData: LiveStop;
    pickupWindow: TimeWindow[];
    deliveryWindow: TimeWindow[];
}): ApiTask => {
    const deliveryStopDataValues = Object.values(deliveryStopData)[0];
    const originalTaskId = deliveryStopData.taskId;

    const {
        pickupLocation,
        props: pickupProps,
        euid: pickupEuid,
        externalTaskType: pickupExternalTaskType,
        pickupCustomerName
    } = pickupStopData || {};

    const { pickupInventory, pickupInvoices, pickupServiceTime } =
        (pickupProps as PickupTaskProps) || {};

    const {
        contact: deliveryContact,
        location: { id: deliveryLocation },
        props: deliveryProps,
        euid: deliveryEuid,
        notification,
        routeDate,
        name: deliveryName,
        externalTaskType: deliveryExternalTaskType,
        sizeByCompartment,
        status,
        locked,
        orderLocked,
        vehicleTypes,
        labels,
        client,
        equipmentId,
        payerOfFreight,
        reservation,
        carKind,
        dropStay,
        deduplicationId,
        consolidationId,
        id,
        _id: underscoreId,
        __v: underscoreV,
        owner
    } = deliveryStopDataValues || {};

    const {
        inventory: deliveryInventory,
        invoices: deliveryInvoices,
        serviceTime: deliveryServiceTime,
        priority,
        externalLinks,
        notes,
        weight,
        pairingEligible,
        size
    } = deliveryProps || {};

    const externalTaskType = getExternalTaskType(
        pickupExternalTaskType,
        deliveryExternalTaskType
    );

    return {
        pickupContact: {
            name: pickupCustomerName || ''
        },
        deliveryContact,
        pickupLocation,
        deliveryLocation,
        props: {
            pickupServiceTime,
            deliveryServiceTime,
            pickupWindow,
            deliveryWindow,
            pickupInventory,
            deliveryInventory,
            originalPickup: pickupStopData,
            originaldelivery: deliveryStopDataValues,
            pickupInvoices,
            deliveryInvoices,
            priority,
            externalLinks,
            notes,
            weight,
            pairingEligible,
            size,
            pickupTime: '',
            deliveryTime: ''
        },
        originalProps: deliveryProps,
        pickupEuid: pickupEuid || '',
        deliveryEuid,
        originalTaskId,
        notification,
        routeDate,
        name: deliveryName,
        externalTaskType,
        sizeByCompartment,
        status,
        locked,
        orderLocked,
        vehicleTypes,
        labels,
        client,
        equipmentId,
        payerOfFreight,
        reservation,
        carKind,
        dropStay,
        deduplicationId,
        consolidationId,
        createdAt: '',
        updatedAt: '',
        id,
        _id: underscoreId,
        __v: underscoreV,
        owner
    };
};
