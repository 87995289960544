import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ApiTask, AxiosApiResponse } from '~/api/types';
import { useTaskManualDispatch } from '~/hooks';
import {
    selectIsSaveAndAssignTaskMode,
    selectIsAssignNewTaskToDispatchedDriver,
    selectSelectedEquipment,
    setSelectedTabIndex
} from '~/reducers/addTaskSlice';
import { selectLiveDriverById } from '~/reducers/liveDriversSlice';
import {
    selectIsEquipmentDepotPairing,
    selectIsOpenPairEquipmentControlPopup,
    setIsOpenDispatchManagementPopup
} from '~/reducers/mapDrawerSettingsSlice';
import { selectDate } from '~/reducers/selectedDateSlice';
import { selectSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';
import { resetSelectedEquipmentIds } from '~/reducers/selectedEquipmentIdsSlice';
import { setSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';
import { getAllTasks, addNewTask } from '~/reducers/tasksSlice';
import { addToast } from '~/reducers/toastsSlice';
import { useAddTask } from '../useAddTask';
import { useClientTaskState } from '../useClientTaskState';
import { useHandleTaskPairing } from '../useHandleTaskPairing';
import {
    ClientTask,
    clientTaskToApiTask,
    emptyClientTask
} from '../utils/addTaskModalUtils';
import { selectGapPosition } from '~/reducers/gapRouteSlice';

export const useOnSaveAndCloseClick = ({
    clientTask,
    hideModal
}: {
    clientTask: Required<ClientTask>;
    hideModal: () => void | undefined;
}) => {
    const dispatch = useDispatch();

    const { t } = useTranslation(['addTask']);

    const { addTask } = useAddTask();

    const selectedDate = useSelector(selectDate);
    const isEquipmentPairing = useSelector(
        selectIsOpenPairEquipmentControlPopup
    );
    const isEquipmentDepotPairing = useSelector(selectIsEquipmentDepotPairing);
    const selectedDrawerCardId = useSelector(selectSelectedDrawerCardId);
    const serialSelectedLiveDriver = useSelector(
        selectLiveDriverById(selectedDrawerCardId)
    );
    const selectedEquipment = useSelector(selectSelectedEquipment);
    const isSaveAndAssignTaskMode = useSelector(selectIsSaveAndAssignTaskMode);
    const isAssignNewTaskToDispatchedDriver = useSelector(
        selectIsAssignNewTaskToDispatchedDriver
    );
    const gapPosition = useSelector(selectGapPosition);

    const { setClientTask } = useClientTaskState();
    const { manualDispatchTask } = useTaskManualDispatch();
    const { handleTaskPairing } = useHandleTaskPairing();

    const onSuccessAssignTask = useCallback(() => {
        dispatch(
            addToast({
                message: t('taskManagement:assignTask.dispatchConfirmation', {
                    count: 1
                }),
                variant: 'info'
            })
        );
    }, [dispatch, t]);

    const onErrorAssignTask = useCallback(() => {
        dispatch(
            addToast({
                message: t('error:assignTasksError'),
                variant: 'error'
            })
        );
    }, [dispatch, t]);

    const handleTaskAssign = useCallback(
        async ({
            driverId,
            apiTask
        }: {
            driverId: string;
            apiTask: ApiTask;
        }) => {
            const tasksToDispatch = [
                {
                    id: apiTask.id
                }
            ];

            const manualDispatchTaskOptions = {
                driverId,
                tasksToDispatch,
                position: gapPosition
            };

            const sideEffects = {
                onSuccess: onSuccessAssignTask,
                onError: onErrorAssignTask
            };

            await manualDispatchTask(manualDispatchTaskOptions, sideEffects);
        },
        [
            manualDispatchTask,
            onSuccessAssignTask,
            onErrorAssignTask,
            gapPosition
        ]
    );

    const onSuccessAddTask = useCallback(
        async (successData: unknown) => {
            const newTask = (successData as AxiosApiResponse<ApiTask>).data
                .data;

            // new task data may not have a route date that matches the selected date
            dispatch(addNewTask(newTask));

            // refresh task data for currently selected date
            dispatch(
                getAllTasks({
                    routeDate: selectedDate
                })
            );

            if (isEquipmentPairing || isEquipmentDepotPairing) {
                handleTaskPairing(newTask);
            }

            dispatch(
                addToast({
                    message: t('addTaskSuccess'),
                    variant: 'success'
                })
            );

            if (selectedEquipment) {
                dispatch(resetSelectedEquipmentIds());
            }

            if (isSaveAndAssignTaskMode || isAssignNewTaskToDispatchedDriver) {
                dispatch(setSelectedTaskIds([newTask.id]));

                if (isSaveAndAssignTaskMode) {
                    dispatch(setIsOpenDispatchManagementPopup(true));
                }

                const driverId = serialSelectedLiveDriver?.id;

                if (driverId) {
                    await handleTaskAssign({
                        driverId,
                        apiTask: newTask
                    });
                }
            }

            setSelectedTabIndex(0);
            setClientTask(emptyClientTask);
            hideModal();
        },
        [
            hideModal,
            setClientTask,
            isEquipmentPairing,
            isEquipmentDepotPairing,
            selectedEquipment,
            dispatch,
            handleTaskPairing,
            handleTaskAssign,
            selectedDate,
            serialSelectedLiveDriver,
            isSaveAndAssignTaskMode,
            isAssignNewTaskToDispatchedDriver,
            t
        ]
    );

    const onErrorAddTask = useCallback(() => {
        dispatch(
            addToast({
                message: t('addTaskFailure'),
                variant: 'error'
            })
        );
    }, [dispatch, t]);

    const handleAddTask = useCallback(
        async (apiTask) => {
            await addTask(apiTask, {
                onSuccess: onSuccessAddTask,
                onError: onErrorAddTask
            });
        },
        [addTask, onErrorAddTask, onSuccessAddTask]
    );

    const onSaveAndCloseClick = useCallback(() => {
        const apiTask = clientTaskToApiTask(clientTask);
        handleAddTask(apiTask);
    }, [clientTask, handleAddTask]);

    return {
        onSaveAndCloseClick
    };
};
