import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PlanStop } from '~/data-classes';
import {
    useToastContext,
    useUpdateAssignmentStatus,
    useWebInterval
} from '~/hooks';
import { TooltipButton } from '~/ui';
import { ModalProps, ModalSize } from '~/ui/components/Modal/types';
import {
    TooltipButtonSizes,
    TooltipButtonVariants
} from '~/ui/components/TooltipButton';

import './UnarriveSelectedStopModal.scss';

export interface UnarriveSelectedStopModalProps {
    Modal: (props: ModalProps) => JSX.Element;
    onClose: () => void;
    stopData: PlanStop | null;
}

const rootClassName = 'unarrive-selected-stop-modal';
const modalContentClassName = `${rootClassName}__modal-content`;
const contentSubjectClassName = `${modalContentClassName}__content-subject`;
const footerClassName = `${rootClassName}__footer`;

export const UnarriveSelectedStopModal = ({
    Modal,
    onClose,
    stopData
}: UnarriveSelectedStopModalProps) => {
    const { t } = useTranslation('translation');

    const { updateAssignmentStatus } = useUpdateAssignmentStatus();
    const { addToast } = useToastContext();
    const { refetch } = useWebInterval();

    const handleClickLiveStopUnarrive = async () => {
        if (stopData) {
            try {
                const { id: stopId } = stopData;
                await updateAssignmentStatus({ stopId, status: 0 });
                addToast({
                    message: t('PlanStopsControl.toast.onSuccessUnarrive'),
                    variant: 'success'
                });
                refetch();
            } catch (error) {
                console.error(error);
                addToast({
                    message: t('PlanStopsControl.toast.onSuccessUnarrive'),
                    variant: 'error'
                });
            }
            onClose();
        }
    };

    return (
        <Modal
            hasCloseButton
            variant={ModalSize.AUTO}
            title={t('UnarriveSelectedStopmodal.title')}
            className={rootClassName}
            data-testid={rootClassName}
        >
            <div className={`${modalContentClassName} _d-flex _fd-column`}>
                <div
                    className={`${contentSubjectClassName} _d-flex _fd-column`}
                >
                    <span className={`${contentSubjectClassName}__text`}>
                        <Trans
                            i18nKey="UnarriveSelectedStopmodal.content_header_text"
                            values={{
                                stopName: stopData?.location.name
                            }}
                            components={{ 1: <b /> }}
                        />
                    </span>
                    <span className={`${contentSubjectClassName}__text`}>
                        <Trans
                            i18nKey="UnarriveSelectedStopmodal.content_body"
                            values={{
                                currentTaskState: t(
                                    'UnarriveSelectedStopmodal.in_progress'
                                ),
                                formerTaskState: t(
                                    'UnarriveSelectedStopmodal.former_incomplete_state'
                                )
                            }}
                            components={{ 1: <b /> }}
                        />
                    </span>
                </div>
                <span className={`${modalContentClassName}__confirm-text`}>
                    {t('UnarriveSelectedStopmodal.confirm_text')}
                </span>
            </div>
            <div className={`${footerClassName} _d-flex _jc-flex-end`}>
                <TooltipButton
                    className={`${footerClassName}__cancel`}
                    variant={TooltipButtonVariants.SECONDARY}
                    size={TooltipButtonSizes.LARGE}
                    onClick={onClose}
                    data-testid={`${footerClassName}__cancel`}
                >
                    {t('UnarriveSelectedStopmodal.cancelButtonLabel')}
                </TooltipButton>
                <TooltipButton
                    className={`${footerClassName}__confirm`}
                    variant={TooltipButtonVariants.PRIMARY}
                    size={TooltipButtonSizes.LARGE}
                    onClick={handleClickLiveStopUnarrive}
                    data-testid={`${footerClassName}__confirm`}
                >
                    {t('UnarriveSelectedStopmodal.confirmButtonLabel')}
                </TooltipButton>
            </div>
        </Modal>
    );
};
