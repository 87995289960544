import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Supercluster from 'supercluster';
import constants from '~/utils/constants';
import { markerUtils } from '~/utils/map';
import { Coordinates } from '~/api/types';
import { useFeatureFlag, useMapUtils } from '~/hooks';
import { selectUnassignedTasksV2 } from '~/reducers/tasksV2Slice';
import { FeatureFlag } from '~/utils/feature-flags-utils';
import { selectUnassignedPlanTasks } from '~/reducers/tasksSlice';

const { DEFAULT_CLUSTER_RADIUS, MAX_ZOOM } = constants.mapOptionSettings;

export const useUnassignedSuperClusters = () => {
    const isEnabledLiveDispatchUi = useFeatureFlag(
        FeatureFlag.LIVE_DISPATCH_UI
    );
    const unassignedPlanTasks = useSelector(selectUnassignedPlanTasks);
    const unassignedTasksV2 = useSelector(selectUnassignedTasksV2);

    const tasks = isEnabledLiveDispatchUi
        ? unassignedTasksV2
        : unassignedPlanTasks;

    const { isClusteringUnassignedTasks } = useMapUtils();

    const schedules = useMemo<
        Supercluster.PointFeature<Supercluster.AnyProps>[]
    >(() => {
        if (!isClusteringUnassignedTasks) {
            return [];
        }

        return tasks.reduce((allPoints, unassignedPlanTask) => {
            const { deliveryLocation, pickupLocation } = unassignedPlanTask;

            // handle the delivery point
            const deliveryCoordinates: Coordinates | undefined =
                deliveryLocation?.location;

            if (deliveryCoordinates?.lat && deliveryCoordinates?.lng) {
                const deliveryPoint = markerUtils.getGeoJSONFeaturePoint(
                    deliveryCoordinates.lat,
                    deliveryCoordinates.lng,
                    unassignedPlanTask
                ) as unknown as Supercluster.PointFeature<Supercluster.AnyProps>;

                allPoints.push(deliveryPoint);
            }

            // handle the pickup point
            const pickupCoordinates: Coordinates | undefined =
                pickupLocation?.location;

            if (pickupCoordinates?.lat && pickupCoordinates?.lng) {
                const pickupPoint = markerUtils.getGeoJSONFeaturePoint(
                    pickupCoordinates.lat,
                    pickupCoordinates.lng,
                    unassignedPlanTask
                ) as unknown as Supercluster.PointFeature<Supercluster.AnyProps>;

                allPoints.push(pickupPoint);
            }

            return allPoints;
        }, []);
    }, [isClusteringUnassignedTasks, tasks]);

    const superClusters = useMemo(() => {
        const superClusterEffects: Supercluster.AnyProps[] = [];

        const superCluster = new Supercluster({
            radius: DEFAULT_CLUSTER_RADIUS,
            maxZoom: MAX_ZOOM
        });
        superCluster.load(schedules);
        superClusterEffects.push(superCluster);

        return superClusterEffects;
    }, [schedules]);

    return {
        schedules,
        superClusters
    };
};
