import { partition } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ApiLiveDispatchV2,
    ApiTask,
    TaskStatus,
    TaskStatusCondition
} from '~/api/types';
import { useFeatureFlag, useGetTasksV2 } from '~/hooks';
import {
    selectSortConfig,
    selectTasksColumnFilters
} from '~/reducers/liveDispatchTableSlice';
import { selectDateOnly } from '~/reducers/selectedDateSlice';
import {
    addTasks,
    selectNewTaskRefreshActive,
    updateTaskRefresh
} from '~/reducers/tasksSlice';
import { FeatureFlag } from '~/utils/feature-flags-utils';
import { FilteredUnassignedTasks } from '../useLiveDispatchTasks/types';
import {
    setTasksV2Attributes,
    setTotalUnassignedTasksV2Count,
    setTotalUnassignedTasksV2PageCount,
    setUnassignedTasksV2
} from '~/reducers/tasksV2Slice';
import { UseLiveDispatchTasksV2Props } from './types';
import { GetTasksV2Params } from '~/api/TasksV2Api';
import { useLiveDispatchTableStates } from '~/components/MapPageDrawers/LiveDispatchDrawer/useLiveDispatchTableStates';

export const useLiveDispatchUnassignedTasksV2 = ({
    selectedTaskIds,
    apiPaginationOptions,
    noFilterNeeded
}: UseLiveDispatchTasksV2Props) => {
    const dispatch = useDispatch();
    const sortConfig = useSelector(selectSortConfig);
    const isEnabledLiveDispatchUi = useFeatureFlag(
        FeatureFlag.LIVE_DISPATCH_UI
    );
    const selectedDate = useSelector(selectDateOnly);
    const selectedTasksColumnFilters = useSelector(selectTasksColumnFilters);
    const isTaskRefreshNeeded = useSelector(selectNewTaskRefreshActive);

    const { globalFilter: search } = useLiveDispatchTableStates();

    const filterConfig = useMemo(() => {
        const obj: Record<string, unknown> = {};
        selectedTasksColumnFilters.forEach(({ id, value }) => {
            obj[id] = value;
        });
        return obj;
    }, [selectedTasksColumnFilters]);

    const onSuccess = useCallback(
        (apiLiveDispatch: ApiLiveDispatchV2) => {
            const { data, meta } = apiLiveDispatch;
            dispatch(addTasks(data));
            dispatch(setTasksV2Attributes(meta?.attributes || {}));
            dispatch(setTotalUnassignedTasksV2Count(meta?.total ?? 0));
            dispatch(
                setTotalUnassignedTasksV2PageCount(meta?.totalPageNumber ?? 0)
            );
            dispatch(updateTaskRefresh(false));
        },
        [dispatch]
    );

    const apiOptionsWithoutFilter = {
        status: `${TaskStatus.UNASSIGNED}`
    };

    const apiOptions = useMemo<GetTasksV2Params>(() => {
        return {
            status: `${TaskStatus.UNASSIGNED}`,
            ...apiPaginationOptions,
            sort: sortConfig,
            ...filterConfig,
            search
        };
    }, [apiPaginationOptions, sortConfig, filterConfig, search]);

    /**
     * unassigned tasks data from TasksV2Api
     *
     * retrieves any unassigned task, regardless of route date, up to the defined page limit
     */
    const {
        data: apiTasksData,
        isLoading: isLoadingTasksV2,
        refetch
    } = useGetTasksV2({
        apiOptions: noFilterNeeded
            ? { ...apiOptionsWithoutFilter }
            : { ...apiOptions },
        reactQueryOptions: {
            onSuccess
        }
    });

    useEffect(() => {
        refetch();
    }, [search, refetch]);

    useEffect(() => {
        if (isTaskRefreshNeeded) {
            refetch();
        }
    }, [isTaskRefreshNeeded, refetch]);

    const totalDispatchTasks = apiTasksData?.meta?.total ?? 0;

    /**
     * unassigned tasks data
     */
    const unassignedTasks = useMemo<ApiTask[]>(() => {
        const unassignedTasksData = apiTasksData?.data?.filter((apiTask) => {
            const { status, taskStatus, routeDate } = apiTask;
            if (isEnabledLiveDispatchUi) {
                return (
                    status === TaskStatus.UNASSIGNED &&
                    taskStatus === TaskStatusCondition.UNASSIGNED
                );
            }

            return (
                routeDate === selectedDate &&
                status === TaskStatus.UNASSIGNED &&
                taskStatus === TaskStatusCondition.UNASSIGNED
            );
        });

        const taskData = unassignedTasksData || [];

        return taskData;
    }, [apiTasksData, selectedDate, isEnabledLiveDispatchUi]);

    /**
     * all unassigned tasks filtered into their respective buckets
     */
    const {
        isFiltered,
        selected,
        excluded: excludedUnassignedTasks
    } = useMemo<FilteredUnassignedTasks>(() => {
        const [selectedTasks, excludedTasks] = partition(
            unassignedTasks,
            ({ id }) => {
                return selectedTaskIds?.includes(id);
            }
        );

        const filtered = Boolean(selectedTaskIds?.length);

        return {
            isFiltered: filtered,
            selected: selectedTasks,
            excluded: filtered ? excludedTasks : []
        };
    }, [unassignedTasks, selectedTaskIds]);

    useEffect(() => {
        dispatch(setUnassignedTasksV2(unassignedTasks));
    }, [unassignedTasks, dispatch]);

    return {
        isFiltered,
        unassignedTasks,
        excludedUnassignedTasks,
        totalTasks: totalDispatchTasks,
        isLoadingTasksV2,
        selectedTasks: isFiltered ? selected : unassignedTasks
    };
};
