import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TaskPriority, TaskTypes } from '~/api/types';
import { getCurrentTime } from '~/components/AddTaskModal/utils/addTaskModalUtils';
import { LiveStop, PlanStop } from '~/data-classes';
import type { RootState } from '~/store';

export const defaultOperationalStopTask = {
    deliveryLatitude: '',
    deliveryLongitude: '',
    deliveryAddressStreet: '',
    deliveryAddressApt: '',
    deliveryAddressCity: '',
    deliveryAddressState: '',
    deliveryAddressZipCode: '',
    deliveryCustomerName: '',
    deliveryCustomerId: '',
    deliveryEuid: '',
    pickupLatitude: '',
    pickupLongitude: '',
    pickupAddressStreet: '',
    pickupAddressApt: '',
    pickupAddressCity: '',
    pickupAddressState: '',
    pickupAddressZipCode: '',
    pickupCustomerName: '',
    pickupCustomerId: '',
    pickupEuid: '',
    date: new Date().toISOString(),
    equipmentId: '',
    priority: TaskPriority.LOW,
    routeId: '',
    deliveryServiceTimeMins: '25',
    pickupServiceTimeMins: '25',
    sizeByCompartment: [],
    taskType: TaskTypes.PICKUP,
    deliveryTimeWindows: [
        {
            start: getCurrentTime('start', TaskTypes.DELIVERY).toISOString(),
            end: getCurrentTime('end', TaskTypes.DELIVERY).toISOString()
        }
    ],
    pickupTimeWindows: [
        {
            start: getCurrentTime('start', TaskTypes.PICKUP).toISOString(),
            end: getCurrentTime('end', TaskTypes.PICKUP).toISOString()
        }
    ],
    vehicleType: null,
    size: '',
    weight: '',

    /**
     * Initialize the table with 6 empty rows
     * Empty inventory items are filtered out when the api request to add the task is made
     */
    inventoryItems: [{}, {}, {}, {}, {}, {}],
    notes: [{}],
    externalTaskType: '',
    invoices: [],
    paymentRequired: false
};

export interface TwoPartStopData {
    pickupStopData: PlanStop | LiveStop;
    deliveryStopData: PlanStop | LiveStop;
}

interface AddOperationalStopState {
    isOpenOperationalStopModal: boolean;
    operationalStopTask: typeof defaultOperationalStopTask;
    twoPartStopData?: TwoPartStopData;
}

const initialState: AddOperationalStopState = {
    isOpenOperationalStopModal: false,
    operationalStopTask: { ...defaultOperationalStopTask }
};

export const addOperationalStopsSlice = createSlice({
    name: 'addOperationalStop',
    initialState,
    reducers: {
        setIsOpenOperationalStopModal: (
            state: AddOperationalStopState,
            action: PayloadAction<boolean>
        ) => {
            return {
                ...state,
                isOpenOperationalStopModal: action.payload
            };
        },
        setTwoPartStopData: (
            state: AddOperationalStopState,
            action: PayloadAction<TwoPartStopData>
        ) => {
            return {
                ...state,
                twoPartStopData: { ...action.payload }
            };
        },
        setExternalTaskType: (
            state: AddOperationalStopState,
            action: PayloadAction<string>
        ) => {
            return {
                ...state,
                operationalStopTask: {
                    ...state.operationalStopTask,
                    externalTaskType: action.payload
                }
            };
        },
        setPickupServiceTimeMins: (
            state: AddOperationalStopState,
            action: PayloadAction<string>
        ) => {
            return {
                ...state,
                operationalStopTask: {
                    ...state.operationalStopTask,
                    pickupServiceTimeMins: action.payload
                }
            };
        },
        setPickupAddress: (
            state: AddOperationalStopState,
            action: PayloadAction<Record<string, string | undefined>>
        ) => {
            return {
                ...state,
                operationalStopTask: {
                    ...state.operationalStopTask,
                    ...action.payload
                }
            };
        },
        resetOperationalStopTask: (state: AddOperationalStopState) => {
            return {
                ...state,
                operationalStopTask: { ...defaultOperationalStopTask }
            };
        }
    }
});

export const {
    setIsOpenOperationalStopModal,
    setExternalTaskType,
    setPickupServiceTimeMins,
    resetOperationalStopTask,
    setPickupAddress,
    setTwoPartStopData
} = addOperationalStopsSlice.actions;

export const selectIsOpenOperationalStopModal = (state: RootState): boolean =>
    state.addOperationalStop.isOpenOperationalStopModal;

export const selectOperationalStop = (
    state: RootState
): typeof defaultOperationalStopTask =>
    state.addOperationalStop.operationalStopTask;

export const selectTwoPartStopData = (
    state: RootState
): TwoPartStopData | undefined => state.addOperationalStop.twoPartStopData;

export default addOperationalStopsSlice.reducer;
