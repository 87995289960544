import { useEffect, useRef, Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUnleashContext } from '@unleash/proxy-client-react';

import { stopSessionReplay } from '~/datadogService';

import authService from '~/auth/aws-cognito-auth-service';
import { thirdPartyEventMonitor } from '~/services/thirdPartyEventMonitor';

import clientsAPI from '~/api/ClientsApi';
import { parseSearch, sessionManager } from '~/api/SessionManager';
import { UsersApi } from '~/api/UsersApi';

import { initAxios } from '~/utils/app-utils';
import constants from '~/utils/constants';
import utils from '~/utils/general-utils';
import socketInstance from '~/utils/socket/socket-instance';
import { migrateAdminUserRoleToPermissionGroup } from '~/utils/user-groups-utils';

import { setAppInitialized } from '~/reducers/appGlobalsSlice';
import { setClients } from '~/reducers/clientsSlice';
import { resetOnLogout } from '~/reducers/common-actions';
import { selectCurrentPage } from '~/reducers/currentPageSlice';
import { setCurrentUser } from '~/reducers/currentUserSlice';

import { usePageNavigation } from '../usePageNavigation';
import { useUserRolesAndPermissions } from '../useUserRolesAndPermissions';

import { getMcwAppInitFromLocalStorage, refreshSessionToken } from './utils';
import { MCWAppInit } from './types';
import { ApiClient, AxiosApiResponse, PaginationMetadata } from '~/api/types';

export const useLogin = (
    setUserId: Dispatch<SetStateAction<string>>,
    setUsername: Dispatch<SetStateAction<string>>
) => {
    const dispatch = useDispatch();
    const { goToPage } = usePageNavigation();
    const currentPage = useSelector(selectCurrentPage);
    const refreshTimerId = useRef<NodeJS.Timeout | number>(0);
    const { poolData } = authService;
    const { initUser } = useUserRolesAndPermissions();
    const updateContext = useUnleashContext();

    /**
     * Resets app state and redirects to the login page
     */
    async function logout() {
        clearInterval(refreshTimerId.current as NodeJS.Timeout);
        await authService.logout();
        goToPage(constants.url.LOGOUT);
        stopSessionReplay();

        /**
         * Resets all slices back to initial state
         *
         * This only affects slices that implement `resetOnLogout`
         * as part of the `extraReducers` callback
         *
         * Slices that do not implement `resetOnLogout`
         * will have their current state values preserved in local storage
         */
        dispatch(resetOnLogout());

        goToPage(constants.url.LOGIN);
    }

    /**
     * Clears the app state if the mcwAppInit data was removed from local storage
     */
    async function handleGlobalLogout(e: StorageEvent) {
        const hasLoggedOut =
            e.key === constants.localStorageKeys.MCW_APP_INIT &&
            e.oldValue &&
            !e.newValue;

        if (hasLoggedOut) {
            await logout();
        }
    }

    /**
     * Connects the client to the websocket server and joins the accessible client rooms
     */
    function initSocket(mcwAppInit: MCWAppInit) {
        if (!mcwAppInit) {
            throw new Error('missing app init');
        }
        socketInstance.connectToSocketServer(
            mcwAppInit.data.socketUrl,
            mcwAppInit.requestHeaders.authorization,
            logout
        );
    }

    /**
     * Sets the accessible clients in the store
     */
    function storeAllClients(
        response: AxiosApiResponse<ApiClient[], PaginationMetadata>
    ) {
        const clients = response.data.data;
        const clientsObjById = clients.reduce<Record<string, ApiClient>>(
            (clientsObj, client) => {
                clientsObj[client.id] = client;
                return clientsObj;
            },
            {}
        );
        dispatch(setClients(clientsObjById));
    }

    /**
     * Fetches the user object for the logged in user and saves the user data to local storage
     */
    async function updateUserDetails(mcwAppInit: MCWAppInit) {
        const userResponse = await UsersApi.getCurrentUser();
        const userDetails = userResponse.data.data;
        dispatch(setCurrentUser(userDetails));
        mcwAppInit.data.userDetails = userDetails;
        localStorage.setItem(
            constants.localStorageKeys.MCW_APP_INIT,
            JSON.stringify(mcwAppInit)
        );
    }

    /**
     * Sets an terval to check if the access token is about to expire every 7 minutes.
     * Refreshes the tokens when needed.
     */
    function startSessionRefreshTimer() {
        clearInterval(refreshTimerId.current as NodeJS.Timeout);
        refreshTimerId.current = setInterval(async () => {
            try {
                await refreshSessionToken(poolData);
            } catch (e) {
                console.error(e);
                await logout();
            }
        }, constants.timings.SESSION_REFRESH_INTERVAL);
    }

    /**
     * Attempts to obtain authorization tokens from Cognito or from Solar, depending on the
     * location's search paramters. Throws if none of the expected parameters are provided.
     */
    async function login() {
        const parsedSearch: Record<string, unknown> = parseSearch();
        if (parsedSearch.code) {
            return authService.authenticateCustomLogin(parsedSearch.code);
        }
        if (parsedSearch.origin && parsedSearch.path) {
            const tokens = await sessionManager.getTokensFromOrigin();
            if (tokens) {
                await authService.authenticateUserFromOrigin(tokens);
            }
            return getMcwAppInitFromLocalStorage();
        }
        throw new Error(
            'Attempted login without tokens or cross-domain origin information'
        );
    }

    /**
     * Gets tokens and initializes the app state on successfully login
     */
    useEffect(() => {
        const loginUser = async () => {
            try {
                const localCache = getMcwAppInitFromLocalStorage();

                const mcwAppInit =
                    localCache ?? ((await login()) as MCWAppInit);

                startSessionRefreshTimer();
                initAxios(mcwAppInit);

                const clientsResponse = await clientsAPI.get();
                initSocket(mcwAppInit);
                dispatch(setAppInitialized(true));
                await updateUserDetails(mcwAppInit);
                const { userDetails } = mcwAppInit.data;
                initUser(userDetails);
                setUserId(userDetails.id);
                setUsername(
                    utils.getUserName(
                        userDetails.firstname,
                        userDetails.lastname,
                        userDetails.username
                    )
                );
                updateContext({ userId: userDetails.username.toLowerCase() });
                await migrateAdminUserRoleToPermissionGroup(
                    userDetails,
                    clientsResponse.data.data
                );
                const clients = clientsResponse.data.data;
                const mainClientId =
                    mcwAppInit.requestHeaders[
                        constants.requestHeaders.WISE_CLIENT_ID
                    ] || clients[0].id;
                storeAllClients(clientsResponse);
                thirdPartyEventMonitor.initialize(
                    clientsResponse.data.data,
                    mainClientId,
                    mcwAppInit.data.userDetails.id
                );
                if (currentPage === constants.url.LOGIN) {
                    goToPage(constants.url.HOME);
                }
                window.addEventListener('storage', handleGlobalLogout);
            } catch (e) {
                console.error(e);
                await logout();
            }
        };
        loginUser();
        return () => {
            window.removeEventListener('storage', handleGlobalLogout);
        };
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return {
        logout
    };
};
