import React, { useState, useImperativeHandle, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { DateTime } from 'luxon';

import {
    useToastContext,
    useFetchMainClientAddress,
    useSelectedClients
} from '~/hooks';

import { setRoutePlansByDate } from '~/reducers/routePlansByDateSlice';
import { selectDateOnly } from '~/reducers/selectedDateSlice';

import { useModal, ActionBarItem } from '~/ui';

import DateSelectModalContent from './DateSelectModalContent';
import { fetchRoutePlansByDateAndUniqueRouteDates } from './utils';
import { selectMainClient } from '~/reducers/mainClientSlice';
import DateUtils from '~/utils/date-utils.ts';

const DateSelectModal = React.forwardRef(function DateSelectModal(
    { onClickDone, isDateAgnosticPage },
    ref
) {
    const {
        hideModal: hideDateModal,
        showModal: showDateModal,
        modal: DateModal
    } = useModal();
    const { t } = useTranslation('translation');
    const { addToast } = useToastContext();

    const [uniqueDates, setUniqueDates] = useState([]);
    const selectedDate = useSelector(selectDateOnly);
    const { areAllMultiClientsSelected, userHasMultiAccess } =
        useSelectedClients();
    const dispatch = useDispatch();
    const { data: { data: mainClientAddress = {} } = {} } =
        useFetchMainClientAddress();
    const { name } = mainClientAddress;

    const addressName = useMemo(() => {
        if (areAllMultiClientsSelected) {
            return t('allClients');
        }
        if (!name && userHasMultiAccess) {
            return t('noClientSelected');
        }
        return name;
    }, [name, userHasMultiAccess, areAllMultiClientsSelected, t]);

    function initDateModalContent() {
        (async () => {
            try {
                const { routePlansByDate, uniqueRouteDates } =
                    await fetchRoutePlansByDateAndUniqueRouteDates();

                dispatch(setRoutePlansByDate(routePlansByDate));
                setUniqueDates(uniqueRouteDates);
            } catch (e) {
                console.error(e);
                addToast({
                    message: t('error:routesFetchError'),
                    variant: 'error'
                });
            }
            showDateModal();
        })();
    }

    const mainClient = useSelector(selectMainClient);

    const dateStationFormatted = useMemo(() => {
        if (!selectedDate || !name) {
            if (userHasMultiAccess) {
                return `${t('DatesAndStations')} - ${t(
                    'noClientDateSelected'
                )}`;
            }
            return `${t('DatesAndStations')} - ${t('noDateSelected')}`;
        }
        const clientTimezone = mainClient?.timezone || 'system';
        const dateTime = DateTime.fromISO(selectedDate);
        const dateString = dateTime
            .setLocale(navigator.language)
            .toLocaleString({
                weekday: 'short',
                month: 'short',
                day: 'numeric'
            });

        const formattedOffset = DateUtils.formatDateOffset(
            dateTime.setZone(clientTimezone).offset
        );

        if (isDateAgnosticPage) return addressName || '';
        if (!addressName) return dateString;

        return `${dateString}, ${addressName}, (${formattedOffset})`;
    }, [
        selectedDate,
        name,
        mainClient?.timezone,
        isDateAgnosticPage,
        addressName,
        userHasMultiAccess,
        t
    ]);

    // expose methods to `ref`
    useImperativeHandle(ref, () => ({
        initDateModalContent,
        setUniqueDates
    }));

    return (
        <>
            <ActionBarItem
                details={dateStationFormatted}
                onClick={initDateModalContent}
                type="noIcon"
            />
            <DateModal hasCustomHeader variant="date">
                <DateSelectModalContent
                    addressName={addressName}
                    dates={uniqueDates}
                    hideModal={hideDateModal}
                    onClickDone={onClickDone}
                    isDateAgnosticPage={isDateAgnosticPage}
                />
            </DateModal>
        </>
    );
});

export default DateSelectModal;
