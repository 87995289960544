import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    setIsOpenUnassignedTasksDrawer,
    selectIsOpenUnassignedTasksDrawer
} from '~/reducers/mapDrawerSettingsSlice';
import { selectOnDemandDispatchTaskCount } from '~/reducers/onDemandDispatchTaskCountSlice';
import { IconButton, theme } from '~/ui';
import { useFeatureFlag, useMapUtils } from '~/hooks';
import { useLiveRoutesUtils } from '~/hooks/useLiveRoutesUtils';
import { FeatureFlag } from '~/utils/feature-flags-utils';
import { selectUnassignedTasksV2 } from '~/reducers/tasksV2Slice';

import './on-demand-dispatch-map-control.scss';

export default function OnDemandDispatchMapControl() {
    const { deselectDriver } = useLiveRoutesUtils();

    const showDrawerOnDemandDispatch = useSelector(
        selectIsOpenUnassignedTasksDrawer
    );
    const onDemandDispatchTaskCount = useSelector(
        selectOnDemandDispatchTaskCount
    );
    const unassignedTasksV2 = useSelector(selectUnassignedTasksV2);

    const isEnabledLiveDispatchUi = useFeatureFlag(
        FeatureFlag.LIVE_DISPATCH_UI
    );

    const hasUnassignedTasksV2 =
        isEnabledLiveDispatchUi && unassignedTasksV2?.length;

    const { isDispatchedRouteMode } = useMapUtils();
    const dispatch = useDispatch();

    const shouldRender = useCallback(() => {
        return (
            isDispatchedRouteMode &&
            (onDemandDispatchTaskCount || hasUnassignedTasksV2)
        );
    }, [
        isDispatchedRouteMode,
        onDemandDispatchTaskCount,
        hasUnassignedTasksV2
    ]);

    function _handleClick() {
        deselectDriver();
        dispatch(setIsOpenUnassignedTasksDrawer(!showDrawerOnDemandDispatch));
    }

    if (!shouldRender()) {
        return null;
    }

    return (
        <IconButton
            className="ondemanddispatchmapcontrol_marker"
            text="?"
            textColor={theme.colors.comet}
            textStyle={{
                ...theme.text['14-medium']
            }}
            style={{
                backgroundColor: theme.colors['galaxy-500']
            }}
            onClick={_handleClick}
        />
    );
}
