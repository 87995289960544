import { isEmpty } from 'lodash';
import { Duration } from 'luxon';

import constants from '~/utils/constants';
import dateUtils from '~/utils/date-utils';
import localizationUtils from '~/utils/localization-utils';
import generalUtils from './general-utils';
import i18n from '~/i18n';

const NO_CONTENT = i18n.t('N/A', { ns: 'common' });
const DYNAMIC_TASK_EUID = i18n.t('dynamic', { ns: 'common' });

function formatAddress(address) {
    return localizationUtils.formatAddress(address);
}

function formatServiceTime(serviceTime) {
    return generalUtils.roundToMaxDigitsAfterDecimal(
        Duration.fromISO(serviceTime).shiftTo('minutes').minutes,
        2
    );
}

function formatTimeWindow({
    timeWindow,
    selectedTimeZone = '',
    isClientTimezoneFlagEnabled = false
}) {
    return (timeWindow || []).map(({ start, end }) => {
        if (dateUtils.isValidDateISO(start)) {
            return dateUtils.getTimeWindowInTimeZone({
                start,
                end,
                timeZone: selectedTimeZone,
                isClientTimezoneFlagEnabled
            });
        }
        // TODO: https://wisesys.atlassian.net/browse/DISP-2672
        return dateUtils.getTimeWindow(start, end);
    });
}

function formatTimeWindowWithDate({
    timeWindow,
    selectedTimeZone,
    isClientTimezoneFlagEnabled
}) {
    return (timeWindow || []).map(({ start, end }) => {
        const formattedTimeWindow = dateUtils.getTimeWindowInTimeZone({
            start,
            end,
            timeZone: selectedTimeZone,
            isClientTimezoneFlagEnabled
        });
        const date = dateUtils.convertISODateToJsDate(start);
        return `${formattedTimeWindow}, ${dateUtils.formatMonthDay(date)}`;
    });
}

function formatTimestamp(timestamp) {
    const date = dateUtils.convertISODateToJsDate(timestamp);
    if (!timestamp || !date) return null;

    return `${dateUtils.formatMonthDay(date)} ${dateUtils.getLocalizedTime(
        timestamp
    )}`;
}

function formatTaskName(task) {
    const pickupLocationName = task?.pickupLocation?.name;
    const deliveryLocationName = task?.deliveryLocation?.name;

    const isTwoPart = checkIsTwoPartTask(task);
    const isDelivery = checkIsDeliveryTask(task);
    const isPickup = checkIsPickupTask(task);

    if (isTwoPart)
        return (
            pickupLocationName &&
            deliveryLocationName &&
            `${pickupLocationName} - ${deliveryLocationName}`
        );
    if (isDelivery) return deliveryLocationName;
    if (isPickup) return pickupLocationName;
}

function formatTaskAddress(task) {
    const isTwoPart = checkIsTwoPartTask(task);
    const isDelivery = checkIsDeliveryTask(task);
    const isPickup = checkIsPickupTask(task);

    if (isTwoPart) {
        const { pickupLocation, deliveryLocation } = task;
        return `${formatAddress(pickupLocation)} - ${formatAddress(
            deliveryLocation
        )}`;
    }
    if (isDelivery) return formatAddress(task.deliveryLocation);
    if (isPickup) return formatAddress(task.pickupLocation);
}

function getEuid(euid) {
    return euid || DYNAMIC_TASK_EUID;
}

function getTaskLocationKey(type) {
    return type?.toLowerCase() === constants.taskTypes.PICKUP
        ? constants.taskTypes.PICKUP_LOCATION
        : constants.taskTypes.DELIVERY_LOCATION;
}
function getTaskInventoryKey(type) {
    return type?.toLowerCase() === constants.taskTypes.PICKUP
        ? constants.taskTypes.PICKUP_INVENTORY
        : constants.taskTypes.DELIVERY_INVENTORY;
}

const getTaskLocationLatLng = (taskLocation) => {
    if (taskLocation?.location) return taskLocation.location;

    if (taskLocation?.geoLocation?.coordinates) {
        const [lng, lat] = taskLocation.geoLocation.coordinates;
        return { lat, lng };
    }

    return {
        lat: null,
        lng: null
    };
};

function checkIsDepot(task) {
    return task?.props?.type === constants.taskTypes.DEPOT;
}

function checkIsTwoPartTask(task) {
    return Boolean(
        task?.[constants.taskTypes.PICKUP_LOCATION] &&
            task?.[constants.taskTypes.DELIVERY_LOCATION]
    );
}

function checkIsDeliveryTask(task) {
    return Boolean(task?.[constants.taskTypes.DELIVERY_LOCATION]);
}

function checkIsPickupTask(task) {
    return Boolean(task?.[constants.taskTypes.PICKUP_LOCATION]);
}

function filterDeliveryTask({
    task,
    selectedTimeZone,
    isClientTimezoneFlagEnabled = false,
    inventoryItems = []
}) {
    const { deliveryLocation, props } = task;
    const { lat, lng } = getTaskLocationLatLng(deliveryLocation);

    return {
        taskName: formatTaskName(task),
        taskAddress: formatTaskAddress(task),
        customerName: task.deliveryLocation.name,
        contactPhone: task.deliveryContact?.phone
            ? task.deliveryContact.phone
            : NO_CONTENT,
        contactEmail: task.deliveryContact?.email
            ? task.deliveryContact.email
            : NO_CONTENT,
        customerAddress: formatAddress(task.deliveryLocation),
        externalTaskType: task.externalTaskType,
        locationLat: lat,
        locationLng: lng,
        timeWindows: formatTimeWindowWithDate({
            timeWindow: props.deliveryWindow,
            selectedTimeZone,
            isClientTimezoneFlagEnabled
        }),
        timeWindowsWithoutDate: formatTimeWindow({
            timeWindow: props.deliveryWindow,
            selectedTimeZone,
            isClientTimezoneFlagEnabled
        }),
        labels: task.labels ?? [],
        notes: props.notes?.length ? props.notes : [{ text: NO_CONTENT }],
        externalLinks: props.externalLinks ? props.externalLinks : [],
        status: task.taskStatus,
        inventoryItems: inventoryItems.filter(
            (item) => item.type === constants.taskTypes.DELIVERY
        ),
        createdAt: task.createdAt,
        updatedAt: task.updatedAt,
        routeId: task.routeId ? task.routeId : '',
        serviceTime: formatServiceTime(props.deliveryServiceTime),
        priority: props.priority,
        weight: props?.weight || NO_CONTENT,
        size: props?.size || NO_CONTENT,
        sizeByCompartment: task.sizeByCompartment,
        euId: getEuid(task?.euid),
        invoicesAndPayments: props.deliveryInvoices ?? [],
        equipmentId: task?.equipmentId || NO_CONTENT
    };
}

function filterPickupTask({
    task,
    selectedTimeZone,
    isClientTimezoneFlagEnabled = false,
    inventoryItems = []
}) {
    const { pickupLocation, props } = task;
    const { lat, lng } = getTaskLocationLatLng(pickupLocation);

    return {
        taskName: formatTaskName(task),
        taskAddress: formatTaskAddress(task),
        customerName: task.pickupLocation.name,
        contactPhone: task.pickupContact?.phone
            ? task.pickupContact.phone
            : NO_CONTENT,
        contactEmail: task.pickupContact?.email
            ? task.pickupContact.email
            : NO_CONTENT,
        customerAddress: formatAddress(task.pickupLocation),
        externalTaskType: task.externalTaskType,
        locationLat: lat,
        locationLng: lng,
        externalLinks: props.externalLinks ? props.externalLinks : [],
        timeWindows: formatTimeWindowWithDate({
            timeWindow: props.pickupWindow,
            selectedTimeZone,
            isClientTimezoneFlagEnabled
        }),
        timeWindowsWithoutDate: formatTimeWindow({
            timeWindow: props.pickupWindow,
            selectedTimeZone,
            isClientTimezoneFlagEnabled
        }),
        labels: task.labels ?? [],
        notes: props.notes?.length ? props.notes : [{ text: NO_CONTENT }],
        status: task.taskStatus,
        inventoryItems: inventoryItems.filter(
            (item) => item.type === constants.taskTypes.PICKUP
        ),
        createdAt: task.createdAt,
        updatedAt: task.updatedAt,
        routeId: task.routeId ? task.routeId : '',
        serviceTime: formatServiceTime(props.pickupServiceTime),
        priority: props.priority,
        weight: props?.weight || NO_CONTENT,
        size: props?.size || NO_CONTENT,
        sizeByCompartment: task.sizeByCompartment,
        euId: getEuid(task?.euid),
        invoicesAndPayments: props.pickupInvoices ?? [],
        equipmentId: task?.equipmentId || NO_CONTENT
    };
}

/**
 * Task metrics without depots included
 */
function removeDepotsFromTaskMetrics(depotTasks, taskMetrics) {
    const updatedMetrics = { ...taskMetrics };

    for (const depot of depotTasks) {
        updatedMetrics.total--;
        switch (depot.status) {
            case constants.taskStatus.UNASSIGNED:
                if (depot.routeId) {
                    updatedMetrics.planned--;
                } else {
                    updatedMetrics.unassigned--;
                }
                break;
            case constants.taskStatus.DISPATCHED:
            case constants.taskStatus.IN_PROGRESS:
                updatedMetrics.dispatched--;
                break;
            case constants.taskStatus.COMPLETED:
                updatedMetrics.completed--;
                break;
            case constants.taskStatus.CANCELLED:
                updatedMetrics.canceled--;
                break;
            default:
        }
    }

    return updatedMetrics;
}

/**
 * Checks task is planned status
 */
function isPlannedTask(task) {
    return (
        task?.status === constants.taskStatus.UNASSIGNED &&
        Boolean(task?.routeId)
    );
}

/**
 * Checks unassigned status is in array
 */
function includesUnassignedStatus(statusArray) {
    return Boolean(statusArray?.includes(constants.taskStatus.UNASSIGNED));
}

/**
 * Checks planned status is in array
 */
function includesPlannedStatus(statusArray) {
    return Boolean(statusArray?.includes(constants.taskStatus.PLANNED));
}

/**
 * Converts web status to API status
 */
function getApiStatusFromWebStatus(selectedStatusFilters) {
    const { taskStatus } = constants;
    if (isEmpty(selectedStatusFilters)) {
        return [
            taskStatus.UNASSIGNED,
            taskStatus.DISPATCHED,
            taskStatus.IN_PROGRESS,
            taskStatus.COMPLETED,
            taskStatus.CANCELLED
        ];
    }

    const webStatusToApiStatus = {
        [taskStatus.UNASSIGNED]: taskStatus.UNASSIGNED,
        [taskStatus.PLANNED]: taskStatus.UNASSIGNED,
        [taskStatus.DISPATCHED]: taskStatus.DISPATCHED,
        [taskStatus.COMPLETED]: taskStatus.COMPLETED,
        [taskStatus.CANCELLED]: taskStatus.CANCELLED
    };
    const updatedTaskStatus = new Set(
        selectedStatusFilters
            .filter((status) =>
                Object.keys(webStatusToApiStatus).includes(`${status}`)
            )
            .map((status) => webStatusToApiStatus[status])
    );
    return Array.from(updatedTaskStatus);
}

/**
 * Get task status, including planned status
 */
function getTaskStatus(task) {
    if (isPlannedTask(task)) {
        return constants.taskStatus.PLANNED;
    }
    return task?.status;
}

/**
 * Format API tasks to web tasks by adding taskStatus property
 */
function formatApiTasksToWebTasks(tasks) {
    return tasks.map((task) => {
        const taskStatus = getTaskStatus(task);
        return { ...task, taskStatus };
    });
}

/**
 * Filter tasks by taskStatus
 */
function filterTasksByTaskStatus(tasks, selectedStatusFilters) {
    if (isEmpty(selectedStatusFilters)) {
        return tasks;
    }

    return tasks.filter((task) => {
        return selectedStatusFilters.includes(task?.taskStatus);
    });
}

function checkIsDefaultEmptyContent(value) {
    return value === NO_CONTENT;
}

function filterTasksByRouteDate(unassignedPlanTasks, selectedDate) {
    return (unassignedPlanTasks ?? []).filter(
        ({ routeDate }) => routeDate === selectedDate
    );
}

export default {
    checkIsDeliveryTask,
    checkIsDepot,
    checkIsPickupTask,
    checkIsTwoPartTask,
    filterDeliveryTask,
    filterPickupTask,
    filterTasksByTaskStatus,
    formatApiTasksToWebTasks,
    formatServiceTime,
    formatTaskAddress,
    formatTaskName,
    formatTimestamp,
    formatTimeWindow,
    formatTimeWindowWithDate,
    getApiStatusFromWebStatus,
    getEuid,
    getTaskInventoryKey,
    getTaskLocationKey,
    getTaskLocationLatLng,
    getTaskStatus,
    includesPlannedStatus,
    includesUnassignedStatus,
    isPlannedTask,
    removeDepotsFromTaskMetrics,
    checkIsDefaultEmptyContent,
    filterTasksByRouteDate
};
